import classed from "classed-components"
import { Button } from "dui"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { LandingHeader } from "routes/container/landing-header"
import { PublicContainer } from "routes/container/public-container"

import { FireService, RepoService } from "service"

const Backdrop = classed.div(`landing bg-cover bg-center 
    h-screen flex flex-col justify-center`)

const Banner = classed.div("text-center items-center")


export const Landing = () => {
    const history = useHistory()
    useEffect(() => {
        FireService.auth.onAuthStateChanged(function(user) {
            const {uid} = user || {}
            RepoService.setUid(uid?? "")
            if (user) {
                history.push("/list")
            }
        })
    }, [])
    return (<PublicContainer>
        <LandingHeader/>
        <Backdrop className="centerXY text-white">
            <div className="p-4 flex flex-row justify-end items-center">
                <div className="text-8 align-top font-sans font-bold pr-4 OKR">OKR</div>
                <div className="font-serif text-4">
                    確實，績效關乎一家企業的存亡，但是， 如何達成績效呢？既然績效是公司的經營成果，
                    採用重視「結果」的績效考核制度，是一般企業普遍的做法。
                    <div className="mt-4 text-2">
                        <Button
                            py="1"
                            onClick={() => history.push("/login")}
                        >
                            登 錄
                        </Button>
                    </div>
                </div>
            </div>
        </Backdrop>
    </PublicContainer>)
}
