import { InlineEdit, LightIndicator, ProgressIndicator, QPanel } from "components"
import { Light } from "components/light"
import { ProgressLabel } from "components/progress-label"
import { IconType } from "dui"
import { PullDown } from "dui/pull-down"
import React, { useEffect, useState } from "react"
import { ChevronLeft } from "react-feather"
import { useHistory } from "react-router"
import { SidePanel } from "routes/container"
import {
    addChildBoard,
    isLocalhost,
    Objective,
    objectivePlaceHolder,
    OkrBoard,
    RepoService,
    TitleUpdater,
} from "service"
import { Box, Container } from "theme-ui"
import { ObjectPanel } from "./object-panel"

interface OkrPanelArg {
    board: OkrBoard
    nav: (next: OkrBoard) => void
}

interface LinkToArgs {
    nav: (next: OkrBoard) => void
    board: OkrBoard
}

const LinkToBoard = (props: LinkToArgs) => {
    const { board, nav } = props
    return (
        <span
            className="gray px-0 pr-2 text-3 font-bold cursor-pointer hover:text-primary-d"
            onClick={() => nav(board)}
        >
            {board.title}
        </span>
    )
}

const BoardNameEdit = (props: { orgTitle: string; callback: Function }) => {
    let [title, setTitle] = useState("")
    useEffect(() => {
        setTitle(props.orgTitle)
    }, [props])
    const updateTitle = (s: string) => {
        props.callback(s)
        //setTitle(s)
    }
    return <InlineEdit key={title} text={title} onSetText={updateTitle} />
}

export const OkrPanel = (props: OkrPanelArg) => {
    const { board, nav } = props
    const { parentBoard } = board
    let [objectives, setObjectives] = useState<Objective[]>([])
    let [subBoards, setBoards] = useState<OkrBoard[]>([])
    let [title, setTitle] = useState<string>("")
    let [sideBar, setSideBar] = useState(false)
    let [season, setSeason] = useState("")
    let [light, _setLight] = useState<string | undefined>(undefined)
    let [progressUnit, _setProgressUint] = useState(board.progressUnit)
    let [progressCurrent, _setProgressCurrent] = useState(board.progressCurrent)
    let [progressTarget, _setProgressTarget] = useState(board.progressTarget)
    let [progressRatio, _setProgressRatio] = useState(board.progressRatio)
    const history = useHistory()

    useEffect(() => {
        console.log(`render in the effect`)
        if (isLocalhost && board.subBoards.length > 0) {
            RepoService.saveLocal(board.boardId)
        }
        setObjectives(board.objectives)
        setBoards(board.subBoards)
        setTitle(board.title)
        setSeason(board.yearQ)
        _setLight(board.light)
        _setProgressUint(board.progressUnit)
        _setProgressRatio(board.progressRatio)
        _setProgressCurrent(board.progressCurrent)
        _setProgressTarget(board.progressTarget)
        // if (isLocalhost && !board.title) {
        //     const restored = RepoService.loadFromLocal()
        //     if (restored) {
        //         setObjectives(restored.objectives)
        //         setBoards(restored.subBoards)
        //         setTitle(restored.title)
        //     }
        // }
    }, [board])

    const setProgressUnit = (s: string) => {
        _setProgressUint(s)
        board.progressUnit = s
    }
    const setProgressTarget = (n: number) => {
        _setProgressTarget(n)
        board.progressTarget = n
    }
    const setProgressCurrent = (n: number) => {
        _setProgressCurrent(n)
        board.progressCurrent = n
    }
    const setProgressRatio = (n: number) => {
        _setProgressRatio(n)
        board.progressRatio = n
    }
    const addObj = () => {
        const extra = objectivePlaceHolder()
        board.objectives.push(extra)
        setObjectives([...board.objectives])
    }

    const remover = (id: string) => () => {
        board.objectives = board.objectives.filter(o => o.id != id)
        setObjectives([...board.objectives])
    }

    const updateTitle = (s: string) => {
        TitleUpdater(board)(s)
        console.log("updated...", board.title, s)
        setTitle(s)
    }

    const onSelectSeason = (s: string) => {
        Object.assign(board, { yearQ: s })
        console.log(`new year-q`, s)
        setSeason(board.yearQ)
    }

    const addBoard = () => {
        // create a board and nav to
        const added = addChildBoard(board, "組織/員工名稱")
        nav(added)
    }

    const removeThisOrg = () => {
        if (board.parentBoard) {
            board.parentBoard.subBoards = board.parentBoard.subBoards.filter(b => b.boardId != board.boardId)
            RepoService.next(board.parentBoard)
        }
    }

    const onSetLight = (s: string) => {
        board.light = s
        _setLight(s)
    }

    return (
        <Container p={4}>
            {parentBoard && (
                <div className="flex flex-row mb-2 color-primary-2 items-center text-2">
                    <ChevronLeft />
                    <LinkToBoard board={parentBoard} nav={nav} />
                </div>
            )}
            <div className="board-header shadow-lg pb-3">
                <div className="flex flex-row items-center pl-2  pr-2">
                    <Box className="text-6 font-bold dark-gray" p={0}>
                        <BoardNameEdit orgTitle={title} callback={updateTitle} />
                    </Box>
                    <div className="ml-1">
                        {parentBoard && <PullDown onClick={removeThisOrg} iconType={IconType.Trash} label="刪 除" />}
                    </div>
                    <div className="flex-grow"></div>
                    {!parentBoard && <div className="text-3 text-dark-gray font-bold">{season}</div>}
                    <ProgressLabel progress={board} />
                    <Light color={light || ""} size="1rem" selected={true} />
                    <PullDown iconType={IconType.MoreVertical} label={"追 蹤"} onClick={() => setSideBar(true)} />
                </div>
                <div className="flex flex-row items-center pl-2">
                    <img
                        className="w-3 mr-2"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzFFMjMyRDt9Cjwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTE4LDJINnY4aDEyVjJ6IE0xNiw4SDhWNGg4Vjh6IE0wLDIzaDZ2LTZIMFYyM3ogTTIsMTloMnYySDJWMTl6IE05LDIzaDZ2LTZIOVYyM3ogTTExLDE5aDJ2MmgtMlYxOXogTTE4LDE3djYgIGg2di02SDE4eiBNMjIsMjFoLTJ2LTJoMlYyMXogTTQsMTRoN3YyaDJ2LTJoN3YyaDJ2LTRIMnY0aDJWMTR6Ii8+PC9zdmc+"
                        alt=""
                    />
                    {subBoards.map((s, i) => (
                        <LinkToBoard key={i} board={s} nav={props.nav} />
                    ))}

                    <PullDown iconType={IconType.Plus} label={" 組織/員工"} onClick={addBoard} />
                </div>
            </div>

            {objectives.map(obj => (
                <ObjectPanel key={obj.id} remover={remover(obj.id)} objective={obj} />
            ))}

            <div className="ml-3 mt-2 flex flex-row items-center">
                <PullDown iconType={IconType.Plus} label={"增加目標"} onClick={addObj} />
            </div>

            <SidePanel
                visible={sideBar}
                onClose={() => {
                    setSideBar(false)
                }}
            >
                {!parentBoard && <QPanel season={season} onSelectSeason={onSelectSeason} />}
                <LightIndicator light={light} setLight={onSetLight} />
            </SidePanel>
        </Container>
    )
}
