import React from "react"
import classed from 'classed-components'

export const LinkButton = classed.button([
    "text-greyLight",
    "background-transparent",
    "font-bold outline-none  mr-1 mb-1",
    "px-1 py-1"
])


