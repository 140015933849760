import classed from "classed-components"
import React, { useState } from "react"
import { MoreVertical } from "react-feather"
import { SidePanel } from "routes/container"
import { getPeriods } from "service"

const Caption = classed.div(["font-sans text-4 font-bold", "cursor-pointer"])
type Season = {
    selected?: boolean
}
const Item = classed.div<Season>([
    ({ selected }) => ({ "bg-color-primary-3": selected }),
    ({ selected }) => ({ "color-primary-1": selected }),

    `color-primary-2 bg-primary-l cursor-pointer
                                   m-1 rounded text-center font-bold text-1
                                   hover:bg-primary-d  hover:text-primary-l`,
])

interface QPanelArgs {
    onSelectSeason: (s: string) => void
    season: string
}

export const QPanel = ({ onSelectSeason, season }: QPanelArgs) => {
    let [selecting, setSelect] = useState(false)
    const showSelect = () => {
        setSelect(true)
    }
    const setSeason = (s: string) => {
        onSelectSeason(s)
        setSelect(false)
    }
    return (
        <div className="pod">
            <div className="pod-header">選擇年份季度</div>
            <div className="pod-content grid grid-cols-4">
                {getPeriods().map(p => (
                    <Item
                        key={p}
                        selected={season === p}
                        onClick={() => setSeason(p)}
                        className="color-primary-2
                                   bg-primary-l
                                   cursor-pointer
                                   m-1
                                   rounded
                                    text-center font-bold text-1
                                    hover:bg-primary-d
                                    hover:text-primary-l
                                    "
                    >
                        {p}
                    </Item>
                ))}
            </div>
        </div>
    )
}
