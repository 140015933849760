import classed from "classed-components"
import { DeleteBtn, InlineEdit, LightIndicator } from "components"
import { Light } from "components/light"
import { IconType } from "dui"
import { PullDown } from "dui/pull-down"
import React, { useEffect, useState } from "react"
import { Navigation2 } from "react-feather"
import { SidePanel } from "routes/container"
import { KeyResult, Objective, shortId, TitleUpdater } from "service"
import { ResultPanel } from "./result-panel"
import { Plus } from "react-feather"

const AddObject = classed.div(`bg-white text-2 pl-5 pb-2 
                letterSpacing-2 cursor-pointer  flex flex-row
                text-gray hover:text-black`)

interface ObjectPanelArg {
    objective: Objective
    remover: Function
}

const defaultKR = "...新的關鍵結果..."

export const ObjectPanel = (props: ObjectPanelArg) => {
    const { objective } = props
    let [title, setTitle] = useState(objective.title)
    let [results, setResults] = useState<KeyResult[]>([])
    let [sideBar, setSideBar] = useState(false)
    let [light, _setLight] = useState<string | undefined>(undefined)

    useEffect(() => {
        _setLight(objective.light)
        setResults(objective.keyResults)
    }, [objective])

    const onSetLight = (s: string) => {
        _setLight(s)
        objective.light = s
    }

    const updateTitle = (s: string) => {
        TitleUpdater(objective)(s)
        setTitle(s)
    }
    const addKeyResult = () => {
        objective.keyResults = [...objective.keyResults, { title: defaultKR, id: shortId() }]
        setResults([...objective.keyResults])
    }

    const removeKeyResult = (id: string) => () => {
        objective.keyResults = objective.keyResults.filter(k => k.id != id)
        setResults([...objective.keyResults])
    }

    return (
        <div
            className="font-bold text-4 dark-gray ml-2 bg-color-primary-1
            rounded-t-lg
            shadow-xl"
        >
            <div
                className="flex flex-row items-center
            object-title
            justify-between pl-1 "
            >
                <span className="mr-2 text-dark-gray">
                    <Navigation2 />
                </span>
                <div className="flex-grow">
                    <InlineEdit text={objective.title} onSetText={updateTitle} />
                </div>
                <Light color={light || ""} size="1rem" selected={true} />
                <div className="flex flex-row items-center justify-between pr-2">
                    <DeleteBtn iconOnly={true} onClick={() => props.remover()} />
                    <PullDown iconType={IconType.MoreVertical} label={"追 蹤"} onClick={() => setSideBar(true)} />
                </div>
            </div>
            {results.map(r => (
                <ResultPanel remover={removeKeyResult(r.id)} key={r.id} keyResult={r} />
            ))}
            <div className="pt-1 bg-white">
                <AddObject onClick={addKeyResult}>
                    <span>
                        <Plus />
                    </span>
                    <span className="pl-2">關鍵結果</span>
                </AddObject>
            </div>

            <SidePanel visible={sideBar} onClose={() => setSideBar(false)}>
                <LightIndicator light={light} setLight={onSetLight} />
            </SidePanel>
        </div>
    )
}
