import React from "react"
import classed from "classed-components"
import { Progress } from "service"

interface Arg {
    progress: Progress
}

export const ProgressLabel = (props: Arg) => {
    const { progress } = props
    const { progressCurrent, progressTarget, progressUnit, progressRatio } = progress
    return (
        <div className="progress">
            {progressTarget && progressCurrent && (
                <div className="">
                    <span className="current">{progressCurrent}</span>
                    {"/"}
                    <span className="target">{progressTarget}</span>
                    <span className="ml-1 unit">{progressUnit}</span>
                </div>
            )}

            {progressRatio && <div className="ratio">{progressRatio} %</div>}
        </div>
    )
}
