import * as React from "react"
import { AppRoutes } from "routes"
import { ThemeProvider } from "theme-ui"
import { appTheme } from "./theme"

export const App = () => (
    <ThemeProvider theme={appTheme}>
        <AppRoutes/>
    </ThemeProvider>
)
