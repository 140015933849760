import classed from "classed-components"
import React from "react"
import { ChevronDown, MoreVertical, Delete, Minus, Plus, Trash2, X, Edit } from "react-feather"

export const Icon = classed.span(["py-1 my-1 cursor-pointer", "text-4", "hover:bg-primary", "hover:text-black"])

export enum IconType {
    Plus,
    PullDown,
    Delete,
    Minus,
    Trash,
    X,
    Edit,
    MoreVertical,
}

interface IconInterface {
    type: IconType
}

export const FatherIcon = ({ type }: IconInterface) => {
    switch (type) {
        case IconType.Plus:
            return <Plus />
        case IconType.Delete:
            return <Delete />
        case IconType.PullDown:
            return <ChevronDown />
        case IconType.Minus:
            return <Minus />
        case IconType.Trash:
            return <Trash2 />
        case IconType.X:
            return <X />
        case IconType.Edit:
            return <Edit />
        case IconType.MoreVertical:
            return <MoreVertical />
    }
}
