import { plainToClass } from "class-transformer"
import { BehaviorSubject } from "rxjs"
import { FireService } from "./fireservice"
import { MockService } from "./mock"
import { ensureLink, longId, OkrBoard } from "./okrBoard"

const YAML = require("yamljs")
const merge = require("lodash.merge")

const removeEmpty = (obj: any) => {
    Object.keys(obj).forEach(
        k =>
            (obj[k] && typeof obj[k] === "object" && removeEmpty(obj[k])) ||
            ((!obj[k] || obj[k] === undefined) && delete obj[k])
    )
    return obj
}

const findSubBoards = (id: string, board: OkrBoard) => {
    if (board.boardId === id) return [board]
    const level: OkrBoard[] = board.subBoards.filter(d => d.boardId === id)
    const { subBoards } = board
    const subs: OkrBoard[][] = subBoards.map(b => findSubBoards(id, b))
    const flat = subs.flat()
    return level.concat(flat)
}

class BoardRepo {
    public board$ = new BehaviorSubject<OkrBoard>(MockService.emptyBoard)
    public uid: string = ""
    private board: OkrBoard = MockService.emptyBoard

    constructor() {}

    populate(board: OkrBoard) {
        this.board = board
        this.board$.next(this.board)
    }

    saveLocal(boardId: string) {
        const clone = removeEmpty(OkrBoard.clone(this.board))
        localStorage.setItem("okrBoard", JSON.stringify({ clone, boardId }))
    }

    loadFromLocal() {
        const str = localStorage.getItem("okrBoard")
        if (str) {
            const obj = JSON.parse(str)
            const { boardId, clone } = obj
            const restored = plainToClass(OkrBoard, clone)
            ensureLink(restored as any)
            this.board = restored as any
            const matched = findSubBoards(boardId, restored as any)
            return matched.length > 0 ? matched[0] : undefined
        }
        return undefined
    }

    async saveBoard() {
        const clone = { ...removeEmpty(OkrBoard.clone(this.board)), timestamp: new Date() }
        const { boardId } = clone
        await FireService.db
            .collection("boards")
            .doc(boardId)
            .set(JSON.parse(JSON.stringify(clone)))
        console.log("saved", clone)
    }

    async saveAsNewCopy() {
        const nextid = longId()
        const clone = { ...removeEmpty(OkrBoard.clone(this.board)), boardId: nextid, timestamp: new Date() }
        const { boardId } = clone
        await FireService.db
            .collection("boards")
            .doc(boardId)
            .set(JSON.parse(JSON.stringify(clone)))
        this.board.boardId = nextid
        console.log("saved", clone)
    }

    async loadBoard() {
        const id = FireService.auth.currentUser!.uid
        const ref = FireService.db.collection("boards").doc(id)
        const snapshot = await ref.get()
        const unlinkObj: OkrBoard = plainToClass(OkrBoard, snapshot.data())
        const restoredObj = ensureLink(unlinkObj as any)
        this.board = restoredObj!
        this.board$.next(restoredObj!)
    }

    async deleteBoard(boardId: string) {
        const ref = FireService.db.collection("boards").doc(boardId)
        await ref.delete()
    }

    async boardsFromDb() {
        const matchId = this.getUid()
        const snapshot = await FireService.db
            .collection("boards")
            .where("uid", "==", matchId)
            .get()
        const dataObjs: any[] = []
        snapshot.forEach(doc => {
            dataObjs.push(doc.data())
        })
        const boards: OkrBoard[] = dataObjs.map(d => {
            const unlinkObj = plainToClass(OkrBoard, d as any)
            return <OkrBoard>ensureLink(unlinkObj as any)
        })
        return boards
    }

    setUid(id: string) {
        localStorage.setItem("boardUid", id)
        this.uid = id
    }

    getUid() {
        return localStorage.getItem("boardUid") || "who-are-you"
    }

    next(board: OkrBoard) {
        this.populate(board)
    }
}

export const RepoService = new BoardRepo()
