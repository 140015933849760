import { FireService } from "service/fireservice"

const shortid = require("shortid")
export const shortId = () => shortid.generate()
export const longId = () => shortId() + shortId()

export interface Light {
    light?: string
}

export interface Progress extends Light {
    progressTarget?: number
    progressCurrent?: number
    progressUnit?: string
    progressRatio?: number
}

class Title implements Progress {
    public title: string = ""
    public owner: string = ""
    public light?: string
    public progressTarget?: number
    public progressCurrent?: number
    public progressUnit?: string
    public progressRatio?: number

    constructor() {}

    addTitle(t: string) {
        this.title = t
        return this
    }

    addOwner(owner: string) {
        this.owner = owner
        return this
    }
}

export interface KeyResult extends Progress {
    id: string
    title: string
    owner?: string
}

export interface Objective extends Progress {
    id: string
    title: string
    description?: string
    keyResults: KeyResult[]
}

export const objectivePlaceHolder = () => {
    const holder: Objective = {
        id: shortId(),
        title: "... 新目標 ...",
        keyResults: [{ id: shortId(), title: "... 新的關鍵結果 ..." }],
    }
    return holder
}

class ObjectiveBuilder {
    constructor(private objective: Objective, private ownerBoard: OkrBoard) {}

    board() {
        return this.ownerBoard
    }

    addKeyResult(title: string) {
        const keyR: KeyResult = { id: shortId(), title }
        this.objective.keyResults.push(keyR)
        return this
    }
}

type UpdateFunc = (text: string) => void
export const TitleUpdater = (obj: Objective | KeyResult | OkrBoard) => (text: string) => {
    Object.assign(obj, { title: text })
    console.log("after updated", obj)
}

export const OwnerUpdater = (obj: Objective | KeyResult | OkrBoard) => (text: string) => {
    Object.assign(obj, { owner: text })
}

export const deLink = (board: OkrBoard) => {
    board.subBoards.forEach(b => {
        b.parentBoard = undefined
        deLink(b)
    })
}

const strangeCopy = (board: OkrBoard): OkrBoard => {
    let clone = { ...board, subBoards: <OkrBoard[]>[], parentBoard: undefined }
    let cloneSub = board.subBoards.map(b => strangeCopy(b))
    clone.subBoards = cloneSub
    return clone as OkrBoard
}

export const ensureLink = (board: OkrBoard) => {
    if (!board) return

    board.subBoards.forEach(b => {
        b.parentBoard = board
        ensureLink(b)
    })

    return board
}

export const addChildBoard = (board: OkrBoard, subBoardTitle: string) => {
    const added = new OkrBoard("", "")
    added.addParentBoard(board).addTitle(subBoardTitle)
    board.subBoards.push(added)
    return added
}

export class OkrBoard extends Title {
    objectives: Objective[] = []
    public parentBoard?: OkrBoard
    public subBoards: OkrBoard[] = []
    public boardId: string
    public uid: string
    public timestamp: Date
    public yearQ: string

    constructor(id: string, uid: string) {
        super()
        this.boardId = id ? id : longId()
        this.uid = uid
        this.timestamp = new Date()
        this.yearQ = ""
    }

    static clone(source: OkrBoard): OkrBoard {
        return strangeCopy(source)
    }

    addParentBoard(parent: OkrBoard) {
        this.parentBoard = parent
        return this
    }

    parent() {
        return this.parentBoard
    }

    addObjective(title: string, description: string = "") {
        const added: Objective = {
            id: shortId(),
            title,
            description,
            keyResults: [],
        }
        this.objectives.push(added)
        return new ObjectiveBuilder(added, this)
    }

    addSubBoard(title: string) {
        const added = new OkrBoard("", this.uid)
        added.addParentBoard(this).addTitle(title)
        this.subBoards.push(added)
        return added.addTitle(title)
    }
}
