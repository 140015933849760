import classed from "classed-components"
import { IconType } from "dui"
import { PullDown } from "dui/pull-down"
import React, { useState } from "react"
import { clickable } from "./types"

const SafeBtn = classed.div("btn")
const WarningBtn = classed.div("btn btn-dangerous")

interface DeleteBtnArgs extends clickable {
    size?: "1" | "2" | "3" | "4"
    iconOnly?: boolean
}

interface SizeArgs {
    size?: "1" | "2" | "3" | "4"
}

const HostBtn = classed.div<SizeArgs>([
    "cursor-pointer text-dark-gray justify-center hover:text-primary-d font-bold letterSpacing-2",
    ({ size }) => {
        size = size || "2"
        const key = `text-${size}`
        const o: any = {}
        o[key] = true
        return o
    },
])
export const DeleteBtn = ({ onClick, size, iconOnly }: DeleteBtnArgs) => {
    let [deleting, setDelete] = useState(false)
    const areYouSure = () => {
        setDelete(true)
        setTimeout(() => {
            setDelete(false)
        }, 1000)
    }
    const onDelete = () => {
        onClick()
    }

    return (
        <HostBtn size={size}>
            {deleting && (
                <WarningBtn onClick={onDelete} className="text-danger">
                    點擊確定
                </WarningBtn>
            )}
            {!deleting && (
                <SafeBtn onClick={areYouSure}>
                    {iconOnly ? <PullDown label={"刪 除"} iconType={IconType.Trash} /> : <span>刪除</span>}
                </SafeBtn>
            )}
        </HostBtn>
    )
}
