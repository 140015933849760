import { clickable, DeleteBtn } from "components"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { mockBoard, OkrBoard, RepoService } from "service"
import { formatRelative, parseISO } from "date-fns"
interface BoardArgs {
    boards: OkrBoard[]
}

const AsDateTime = (tm: any) => {
    return tm ? <div>{formatRelative(parseISO(tm), new Date())}</div> : <div />
}

const selectBoard = (board: OkrBoard) => {
    RepoService.next(board)
}

interface BoardIntf extends clickable {
    board: OkrBoard
}

const Board = (props: BoardIntf) => {
    const history = useHistory()
    const { board: b, onClick } = props
    const selectBoard = (board: OkrBoard) => {
        RepoService.populate(board)
        history.push("/board")
    }
    return (
        <React.Fragment>
            <div className="font-light mt-1 select-file" onClick={() => selectBoard(b)} key={b.boardId}>
                {b.title}
            </div>
            <div className="text-black mt-1" key={b.boardId + "year"}>
                {b.yearQ || ""}
            </div>
            <div className="timestamp mt-1" key={b.boardId + "tm"}>
                {AsDateTime(b.timestamp)}
            </div>
            <div>
                <DeleteBtn onClick={onClick} />
            </div>
        </React.Fragment>
    )
}

const BoardListTable = ({ boards }: BoardArgs) => {
    let [view, setView] = useState(boards)

    const onDelete = async (board: OkrBoard) => {
        const next: OkrBoard[] = view.filter(b => b.boardId != board.boardId)
        await RepoService.deleteBoard(board.boardId)
        setView(next)
        console.log(board)
    }

    return (
        <div>
            <div className="grid grid-cols-4 text-black font-bold p-4 ">
                <div>文檔名稱</div>
                <div>年季</div>
                <div>修改時間</div>
                <div>
                    <span>&nbsp;</span>
                </div>
                {view.map((b: OkrBoard) => (
                    <Board
                        key={b.boardId}
                        board={b}
                        onClick={() => {
                            onDelete(b)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

export const List = () => {
    let [boards, setBoards] = useState<OkrBoard[]>([])
    let [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        ;(async function() {
            const list = await RepoService.boardsFromDb()
            if (list.length === 0) {
                list.push(mockBoard("", RepoService.getUid()))
            }
            const sorted = list.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
            setBoards(list)
            setLoading(false)
        })()
    }, [])

    return (
        <div>
            {loading && <div>Loading</div>}
            {!loading && <BoardListTable boards={boards} />}
        </div>
    )
}
