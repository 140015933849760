/** @jsx jsx */
import classed from "classed-components"
import { LinkDiv } from "dui"
import React from "react"
import { Copy, DownloadCloud, LogOut, Save } from "react-feather"
import { useHistory } from "react-router"
import { useToasts } from "react-toast-notifications"
import { FireService, RepoService } from "service"
import { jsx } from "theme-ui"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"


const Dropdown = () => {
    return (
        <div className="dropdown inline-block relative ">
            <button className="text-gray font-semibold py-2 px-4
            hover:bg-secondary
            hover:text-white
            rounded inline-flex items-center">
                <span className="mr-1">Dropdown</span>
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
            </button>
            <ul className="dropdown-menu absolute hidden bg-gray rounded text-black pt-1">
                <li className=""><a
                    className="rounded-t bg-gray-200 hover:bg py-2 px-4 block whitespace-no-wrap"
                    href="#">One</a></li>
                <li className=""><a className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                                    href="#">Two</a></li>
                <li className=""><a
                    className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">Three
                    is the magic number</a></li>
            </ul>
        </div>
    )
}


export const NavHeader = () => {
    const history = useHistory()
    const { addToast } = useToasts()
    const logout = async () => {
        await FireService.auth.signOut()
        history.push("/")
    }
    const save = async () => {
        try {
            await RepoService.saveBoard()
            addToast("保 存 成 功", { appearance: "success" })
        } catch (e) {
            addToast(e.message, { appearance: "error" })
            console.log(e)
        }
    }

    const saveNewVersion = async () => {
        try {
            await RepoService.saveAsNewCopy()
            addToast("保 存 成 功", { appearance: "success" })
        } catch (e) {
            addToast(e.message, { appearance: "error" })
            console.log(e)
        }
    }


    const load = async () => {
        await RepoService.loadBoard()
        addToast("重新加載成功", { appearance: "success" })
    }

    return (<header className="flex center pt-2">

            <Switch>
                <Route path="/board" exact>
                    <LinkDiv onClick={save} className="text-3 letterSpacing-2  ">
                        <Save/>
                        <div className="ml-1">保存</div>
                    </LinkDiv>
                    <LinkDiv onClick={saveNewVersion} className="text-3 letterSpacing-2  ">
                        <Copy/>
                        <div className="ml-1">保存新版本</div>
                    </LinkDiv>
                </Route>
            </Switch>


            <div className="mx-auto"/>

            <LinkDiv onClick={logout} className="text-3 letterSpacing-2 mr-2">
                <LogOut/>
                <div className="ml-1">登出</div>
            </LinkDiv>

        </header>
    )
}
