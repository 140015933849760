"use strict";
exports.__esModule = true;
exports.appTheme = void 0;
var preset_deep_1 = require("@theme-ui/preset-deep");
var preset_bulma_1 = require("@theme-ui/preset-bulma");
var theme_ui_1 = require("theme-ui");
var condense = function (themes) {
    return themes.reduce(function (a, c) { return theme_ui_1.merge(a, c); }, {});
};
// https://material-ui.com/customization/palette/
var patch = {
    fonts: {
        body: "'Open Sans', 'Noto Serif TC', 'Microsoft Yahei', 'WenQuanYi Micro Hei', 'ST Heiti'",
        heading: "'Open Sans','Noto Serif TC', sans-serif",
        monospace: "monospace",
        sans: "'Open Sans'",
        serif: "'Open Sans', 'Noto Serif TC', 'Microsoft Yahei', 'WenQuanYi Micro Hei', 'ST Heiti'"
    },
    colors: {
        primary: "rgba(112, 113, 173, 1)",
        secondary: "#008273",
        text: "#f6f6f8",
        "primary-d": "rgba(19, 21, 78, 1)",
        "primary-l": "rgba(195, 196, 231, 1)",
        "secondary-d": "#001815",
        "secondary-l": "#00dcc2",
        info: "#0e2e84",
        success: "#4caf50",
        warning: "#ff9800",
        error: "#f44336",
        "light-gray": "#eff1f1",
        "dark-gray": "#9ba5aa",
        white: "white",
        black: "black",
        gray: "#9E9E9E"
    }
};
var combinedTheme = condense([preset_bulma_1["default"], preset_deep_1["default"], patch]);
exports.appTheme = combinedTheme;
