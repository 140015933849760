import React from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom"
import { Landing } from "routes/landing"
import { FireService} from  "service"


const isLogin = () => (FireService.auth.currentUser)

export const PrivateRoute = ({ component, ...options } : RouteProps) => {
    const finalComponent = isLogin() ? component : Landing;
    return <Route {...options} component={finalComponent} />;
};
