/** @jsx jsx */
import classed from "classed-components"
import React from "react"
import { jsx } from "theme-ui"

const NavBtn = classed.div(`sans pointer p-2 
text-white cursor-pointer 
hover:bg-primary`)

export const LandingHeader = () => {
    return (
        <header className="landing-navbar flex p-4">
            <div className="IDEALLABS">
                <span className="font-bold mr-1 ">Ideal</span>
                <span className="font-thin">Labs</span>
            </div>
            <div className="mx-auto" />
            <NavBtn>隱私權</NavBtn>
            <NavBtn>服務條款</NavBtn>
        </header>
    )
}
