import { FatherIcon, Icon, IconType } from "dui"
import React, { useRef } from "react"
import ReactTooltip from "react-tooltip"
import { shortId } from "service"

interface PullDownInterface {
    iconType: IconType
    label?: string
    onClick?: Function
}

export const PullDown = ({ iconType, label, onClick }: PullDownInterface) => {
    const randId = shortId()
    const wrapperRef = useRef(null)
    const fire = () => {
        if (onClick) onClick()
    }

    return (
        <div className="relative inline-block " ref={wrapperRef}>
            <div className="btn-subtle" data-tip data-for={randId}>
                <Icon onClick={() => fire()}>
                    <FatherIcon type={iconType} />
                </Icon>
            </div>
            <ReactTooltip className="letterSpacing-2" id={randId} place="bottom" effect="solid">
                {label}
            </ReactTooltip>
        </div>
    )
}
