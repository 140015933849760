import { GlossOver } from "components"
import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { X } from "react-feather"

interface sidePanelArgs {
    visible: boolean
    onClose: () => void
    children: any
}

export const SidePanel = (props: sidePanelArgs) => {
    const { visible, children } = props
    let [glossOverIt, setGlossOver] = useState(false)
    let [sideAnimate, setSideAnimate] = useState<any>({})

    useEffect(() => {
        const effect = visible ? { opacity: 1, display: "block" } : { opacity: 0, display: "none" }
        setSideAnimate(effect)
        setGlossOver(visible)
    }, [visible])

    return (
        <>
            <GlossOver visible={glossOverIt} />
            <motion.div className="sidebar" transition={{ duration: 0.9 }} animate={sideAnimate}>
                <div className="flex flex-row flex-end p-2">
                    <X onClick={props.onClose} className="gray hover:text-white cursor-pointer" />
                    <div className="flex-grow" />
                </div>
                <div className="p-2 flex-grow">{children}</div>
            </motion.div>
        </>
    )
}
