import classed from "classed-components"

export const LinkDiv = classed.div([
    "flex flex-row items-center",
    "cursor-pointer",
    "text-greyLight",
    "hover:text-primary",
    "background-transparent",
    "font-bold  text-sm outline-none focus:outline-none mr-1 mb-1",
    "px-1 py-1"
])
