import { DeleteBtn, InlineEdit, ProgressIndicator } from "components"
import { ProgressLabel } from "components/progress-label"
import { IconType } from "dui"
import { PullDown } from "dui/pull-down"
import React, { useEffect, useState } from "react"
import { Activity } from "react-feather"
import { SidePanel } from "routes/container"
import { KeyResult, OwnerUpdater, TitleUpdater } from "service"

interface resultArg {
    keyResult: KeyResult
    remover: () => void
}

export const ResultPanel = (props: resultArg) => {
    const { keyResult } = props
    let [title, setTitle] = useState(keyResult.title)
    let [owner, setOwner] = useState(keyResult.owner || "")
    let [sidebar, setSidebar] = useState(false)
    const openSidebar = () => setSidebar(true)
    let [sideBar, setSideBar] = useState(false)
    let [progressUnit, _setProgressUint] = useState(keyResult.progressUnit)
    let [progressCurrent, _setProgressCurrent] = useState(keyResult.progressCurrent)
    let [progressTarget, _setProgressTarget] = useState(keyResult.progressTarget)
    let [progressRatio, _setProgressRatio] = useState(keyResult.progressRatio)
    useEffect(() => {
        _setProgressUint(keyResult.progressUnit)
        _setProgressRatio(keyResult.progressRatio)
        _setProgressCurrent(keyResult.progressCurrent)
        _setProgressTarget(keyResult.progressTarget)
    }, [keyResult])

    const setProgressUnit = (s: string) => {
        _setProgressUint(s)
        keyResult.progressUnit = s
    }
    const setProgressTarget = (n: number) => {
        _setProgressTarget(n)
        keyResult.progressTarget = n
    }
    const setProgressCurrent = (n: number) => {
        _setProgressCurrent(n)
        keyResult.progressCurrent = n
    }
    const setProgressRatio = (n: number) => {
        _setProgressRatio(n)
        keyResult.progressRatio = n
    }
    const updateTitle = (s: string) => {
        TitleUpdater(keyResult)(s)
        setTitle(s)
    }

    const updateOwner = (s: string) => {
        OwnerUpdater(keyResult)(s)
        setOwner(s)
    }

    return (
        <div className="text-2 font-light pl-1  bg-white">
            <div
                className="flex flex-row items-center border-primary-l
         border-none border-b-2 justify-end pl-4 pr-2"
            >
                <span className="mr-2 text-dark-gray">
                    <Activity />
                </span>
                <div className="flex-grow">
                    <InlineEdit text={title} onSetText={updateTitle} />
                </div>
                <div className="mx-1 text-1 font-bold gray">
                    <InlineEdit text={owner} onSetText={updateOwner} />
                </div>
                <ProgressLabel progress={keyResult} />
                <DeleteBtn iconOnly={true} onClick={props.remover} />
                <div className="">
                    <PullDown iconType={IconType.MoreVertical} label={"追 蹤"} onClick={() => setSideBar(true)} />
                </div>
            </div>
            <SidePanel visible={sideBar} onClose={() => setSideBar(false)}>
                <ProgressIndicator
                    onUnit={setProgressUnit}
                    onCurrent={setProgressCurrent}
                    onRatio={setProgressRatio}
                    onTarget={setProgressTarget}
                    unit={progressUnit || ""}
                    target={progressTarget}
                    current={progressCurrent}
                    ratio={progressRatio}
                />
            </SidePanel>
        </div>
    )
}
