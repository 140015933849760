import { Light } from "components/light"
import React, { useEffect, useState } from "react"
import classed from "classed-components"
const colors = ["gray", "red", "yellow", "green", "blue"]
interface Args {
    setLight: (s: string) => void
    light?: string
}

export const LightIndicator = (props: Args) => {
    let { light, setLight } = props
    let [onColor, setOnColor] = useState("")
    const onSelect = (s: string) => {
        setOnColor(s)
        setLight(s)
    }
    useEffect(() => {
        setOnColor(light ?? "gray")
    }, [light])
    return (
        <div className="pod mt-2">
            <div className="pod-header">進度指示燈</div>
            <div className="pod-content">
                <div className="grid grid-cols-5">
                    {colors.map(c => (
                        <Light key={c} color={c} onClick={() => onSelect(c)} selected={c === onColor} />
                    ))}
                </div>
            </div>
        </div>
    )
}
