import userEvent from "@testing-library/user-event"
import { Button, Input } from "dui"
import React, { useState } from "react"
import { X } from "react-feather"
import { useHistory } from "react-router"
import { ToastProvider, useToasts } from "react-toast-notifications"
import { FireService } from "service"

interface view {
    readonly userName: string
    readonly password: string
}

export const Login = (props: any) => {
    const history = useHistory()
    const backHome = () => {
        history.push("/")
    }
    let [userEmail, setUserEmail] = useState("")
    let [password, setPassword] = useState("")
    const { addToast } = useToasts()
    const capture = (setter: Function) => (e: any) => {
        setter(e.target.value)
    }
    const signIn = async () => {
        const rt = await FireService.signInWithEmailAndPas(userEmail, password)
        if (rt.error) {
            console.log("cc", rt.error)
            addToast(rt.error.message, { appearance: "error" })
        }
    }
    const onResetPassword = async () => {
        try {
            await FireService.auth.sendPasswordResetEmail(userEmail)
            addToast("重置電子郵件鏈接已發送至提供的地址", { appearance: "success" })
        } catch (e) {
            addToast(e.message, { appearance: "error" })
        }
    }
    return (
        <div className="bg-primary-l w-screen h-screen">
            <div className="centerXY h-auto">
                <div
                    className="
                                mt-5
                                text-main
				                justify-between
				                shadow-xl
				                md:w-1/2
				                lg:w-1/3
				                bg-primary-d
				                 pl-4 py-4 pr-1 "
                >
                    <div className="pr-4">
                        <div className="flex flex-row  justify-between">
                            <div className="text-3 font-bold pb-3 letterSpacing-2">歡迎回來 灰熊OKR</div>
                            <X className="icon-button" onClick={backHome} />
                        </div>

                        <div className="pb-3">請輸入您的用戶名和密碼</div>
                        <div className="flex flex-col justify-between font-monospace">
                            <Input
                                name="userName"
                                onChange={capture(setUserEmail)}
                                defaultValue={userEmail}
                                placeholder="電子郵件"
                            />
                            <Input
                                name="password"
                                onChange={capture(setPassword)}
                                defaultValue={password}
                                type="password"
                                placeholder="密碼"
                            />
                        </div>

                        <div className="flex flex-row mt-2 justify-between">
                            <Button onClick={signIn} variant="secondary" px="2" py="1">
                                登 錄
                            </Button>
                            <div
                                className="text-link-on-dark hover:bg-primary hover:text-white p-1"
                                onClick={onResetPassword}
                            >
                                找回密碼
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
