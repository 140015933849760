import { useState } from "react"
import { OkrBoard, Progress } from "service/okrBoard"

export const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export const getPeriods = () => {
    const d = new Date()
    const n = d.getFullYear()
    const years = [n - 1, n, n + 1]
    const seasons = ["第1季", "第2季", "第3季", "第4季"]
    return years
        .map(y => {
            return seasons.map(s => `${y}${s}`)
        })
        .flat()
}
