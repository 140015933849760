import { longId, OkrBoard, shortId } from "service/okrBoard"
import {FireService} from "service/fireservice"

export const mockBoard = (id:string = "", uid:string) => {
    const board = new OkrBoard(id, uid)
    board.addTitle("樣本文件-澎湖縣")
         .addOwner("李建軒")
         .addObjective("為在地業者帶來一波觀光人潮")
         .addKeyResult("8場與全球知名的娛樂企業-華特迪士尼公司旗下的「漫威」品牌合作")
         .addKeyResult("虹橋徵選秀")
         .board()
         .addObjective("打造漫威專場無人機科技", "每場漫威專場演出圖形由官方設計規劃並搭配經典漫威系列電影音樂，視聽效果無可比擬。另外也特別規劃三個週末離島場次分別是7月18日吉貝場、8月1日七美場及8月15日望安場，除了與當地鄉親同樂之外，也能帶領遊客深入漫遊離島，藉由花火讓更多遊客看到不一樣的澎湖小島風景",
         )
         .addKeyResult("2020年規劃花火節專屬愛情活動")
         .addKeyResult("澎湖國際海上花火節")
         .addKeyResult("抓住澎湖之美攝影比賽")
         .board()
         .addSubBoard("警察局")
         .addOwner("楊鴻正")
         .addObjective("虹橋徵選秀")
         .addKeyResult("交通安全")
         .addKeyResult("紓困振興話題正夯,警籲慎防詐騙")
         .board()
         .addObjective("充實本縣緊急醫療救護")
         .addKeyResult("提升本縣緊急醫療救護裝備器材")
         .addKeyResult("強化海水消防栓救災系統功能")
         .board()
         .addSubBoard("徽因")
         .addOwner("徽因")
         .addObjective("澎湖縣縣長信箱")
         .addKeyResult("發布「文化藝術採購辦法")
         .addKeyResult("表揚海洋環境保護及守護海洋有功社區")
         .board()
         .addObjective("發布「文化藝術採購辦法")
         .addKeyResult("武轎踩街祈福")
         .board()
         .parent()!
        .addSubBoard("芷若")
        .addOwner("芷若")
        .addObjective("招募英語營志工")
        .addKeyResult("中華民國第51屆世界兒童畫展")
        .board()

    board.addSubBoard("旅遊處")
         .addObjective("為在地業者帶來一波觀光人潮")
         .addKeyResult("辦理1場華夏劇場舞台劇公演")
         .addKeyResult("公告開放本縣2條市區公車路線，徵求有意願經營之業者參加評選")
         .board()
         .addObjective("毒品危害防制")
         .addKeyResult("訓練109年澎湖縣毒品危害防制志工")
         .addKeyResult("暫停卡介苗接種作業")

    return board
}


const getEmptyBoard = () => {
    return  new OkrBoard("", longId())
}
export const MockService = {
    emptyBoard: getEmptyBoard(),
    mockBoard,
}
