/** @jsx jsx */
import classed from "classed-components"
import React from "react"
import { useHistory } from "react-router"
import { jsx } from "theme-ui"
import { NavHeader } from "./NavHeader"

interface MatchingArg {
    path?: string
}

const isInPath = (path: string) => {
    const full = window.location.toString()
    return full.indexOf(path) >= 0
}

const Menu = classed.div<MatchingArg>([
    `text-3 text-right pr-2 cursor-pointer menu-item `,
    ({ path }) => ({ active: !!path && isInPath(path) }),
])

export const AppContainer = (props: any) => {
    const history = useHistory()

    return (
        <div className="flex flex-wrap">
            <aside
                className="min-h-screen flex flex-col
                side-bar justify-between"
                sx={{
                    flexGrow: 1,
                    flexBasis: "sidebar",
                }}
            >
                <div>
                    <div className="text-8 align-top font-sans font-bold  px-4 OKR">OKR</div>
                    <Menu path="list" onClick={() => history.push("/list")}>
                        所有的文件
                    </Menu>
                    <Menu>Dashboard</Menu>
                    <Menu>Team</Menu>
                    <Menu>Profile</Menu>
                </div>

                <div className="align-bottom p-2 text-right">
                    <a href="//www.tw-original.com/" target="_blank">
                        <div className="sans text-3 license font-bold">原生企管顧問</div>
                    </a>
                    <div className="sans text-3 text-white font-bold">
                        Ideal <span className="font-thin mr-1">Labs</span>
                        授權
                    </div>
                </div>
            </aside>
            <main
                className="bg-white-90"
                sx={{
                    flexGrow: 99999,
                    flexBasis: 0,
                    minWidth: 320,
                }}
            >
                <NavHeader />
                {props.children}
            </main>
        </div>
    )
}
