import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import { Board } from "routes/board"
import { AppContainer } from "routes/container"
import { Landing } from "routes/landing"
import { PrivateRoute } from "routes/private-route"
import { List } from "./list"
import { Login } from "./login"


export const AppRoutes = () => {
    return (
        <ToastProvider placement="top-center" autoDismiss={true} autoDismissTimeout={1500}>


            <Router>
                <Switch>
                    <Route exact path="/">
                        <Landing/>
                    </Route>
                    <Route exact path="/login">
                        <Login/>
                    </Route>
                    <AppContainer>
                        <PrivateRoute exact path="/list">
                            <List/>
                        </PrivateRoute>
                        <PrivateRoute exact path="/board">
                            <Board/>
                        </PrivateRoute>
                    </AppContainer>

                </Switch>
            </Router>
        </ToastProvider>
    )
}
