import { useKeyPress, useOnClickOutside } from "hooks"
import React, { useEffect, useRef, useState } from "react"

function byteLength(str: string) {
    if (!str) {
        return 5
    }
    // returns the byte length of an utf8 string
    let s = str.length
    for (let i = str.length - 1; i >= 0; i--) {
        let code = str.charCodeAt(i)
        if (code > 0x7f && code <= 0x7ff) s++
        else if (code > 0x7ff && code <= 0xffff) s += 2
        if (code >= 0xdc00 && code <= 0xdfff) i-- //trail surrogate
    }
    return s
}

interface InlineEditArgs {
    text: string | number | undefined
    onSetText: Function
}

export function InlineEdit(props: InlineEditArgs) {
    let { text } = props
    text = text ?? ""
    const initValue: string = typeof text === "string" ? text : `${text}`
    const [isInputActive, setIsInputActive] = useState(false)
    const [inputValue, setInputValue] = useState(initValue)
    const wrapperRef = useRef(null)
    const textRef = useRef(null)
    const inputRef = useRef(null)
    const enter = useKeyPress("Enter")
    const esc = useKeyPress("Escape")
    // check to see if the user clicked outside of this component
    useOnClickOutside(wrapperRef, () => {
        if (isInputActive) {
            props.onSetText(inputValue)
            setIsInputActive(false)
        }
    })
    // focus the cursor in the input field on edit start
    useEffect(() => {
        if (isInputActive) {
            // @ts-ignore
            inputRef.current.focus()
        }
    }, [isInputActive])
    useEffect(() => {
        if (isInputActive) {
            // if Enter is pressed, save the text and case the editor
            if (enter) {
                props.onSetText(inputValue)
                setIsInputActive(false)
            }
            // if Escape is pressed, revert the text and close the editor
            if (esc) {
                setInputValue(initValue)
                setIsInputActive(false)
            }
        }
    }, [enter, esc]) // watch the Enter and Escape key presses
    return (
        <span className="inline-text" style={{ width: "300px" }} ref={wrapperRef}>
            <span
                ref={textRef}
                onClick={() => setIsInputActive(true)}
                className={`inline-text_copy inline-text_copy--${!isInputActive ? "active" : "hidden"}`}
                style={{ minWidth: "200px" }}
            >
                {props.text ? props.text : "---"}
            </span>
            <input
                ref={inputRef}
                // set the width to the input length multiplied by the x height
                // it's not quite right but gets it close
                style={{ width: Math.ceil(byteLength(inputValue) * 0.98 + 3) + "ex" }}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value)
                }}
                className={`inline-text_input inline-text_input--${isInputActive ? "active" : "hidden"}`}
            />
        </span>
    )
}
