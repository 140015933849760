import React from "react"


interface titleArg {
    label:string;
}

export const Badge = (props:titleArg) => {
    return <span className="text-1 font-light
               inline-block py-1 px-2 uppercase rounded
               text-white
               bg-dark
            uppercase last:mr-0 mr-1">
            {props.label}
        </span>
}

