import { InlineEdit } from "components/inline-edit"
import React from "react"
import classed from "classed-components"
import { Progress } from "service"
import {wording} from './wording'

const Heading = classed.div(["input-heading"])

interface Arg {
    onUnit: (s: string) => void
    onRatio: (n: number) => void
    onCurrent: (n: number) => void
    onTarget: (n: number) => void
    unit: string
    ratio?: number
    current?: number
    target?: number
}

export const ProgressIndicator = (props: Arg) => {
    return (
        <div className="pod mt-2">
            <div className="pod-header">進度測度</div>
            <div className="pod-content">
                <div className="grid grid-cols-4 text-dark-gray">
                    <div className="m-1">
                        <Heading>{wording.progress}</Heading>
                        <InlineEdit text={props.current} onSetText={props.onCurrent} />
                    </div>
                    <div className="m-1">
                        <Heading>{wording.confidence}</Heading>
                        <InlineEdit text={props.target} onSetText={props.onTarget} />
                    </div>
                    <div className="m-1">
                        <Heading>單位</Heading>
                        <InlineEdit text={props.unit} onSetText={props.onUnit} />
                    </div>
                    <div className="m-1">
                        <Heading>{wording.targetRatio}</Heading>
                        <InlineEdit text={props.ratio} onSetText={props.onRatio} />
                    </div>
                </div>
            </div>
        </div>
    )
}
