import classed from "classed-components"
import React from "react"


interface ButtonProps {
    readonly children?:any;
    readonly onClick?:() => any;
    readonly style?:React.CSSProperties;
    readonly px?:number | string
    readonly py?:number | string
    readonly variant?:"primary" | "secondary" | "icon"
}

export const Button = ({ onClick, children, style, px, py, variant, ...rest }:ButtonProps) => {

    let variantClass = "hover:bg-primary-d hover:text-white bg-primary"
    if (variant === "secondary") {
        variantClass = "hover:bg-info hover:text-white text-white bg-dark"
    }
    if (variant === "icon") {
        variantClass = "hover:bg-info hover:text-white text-white bg-transparent"
    }

    const Btn = classed.span(`cursor-pointer 
            py-${py || 2} 
            px-${px || 2}
            ${variantClass}
            `)
    return (<div style={{ display: "inline" }}>
        <Btn {...rest} onClick={onClick}>{children}</Btn>
    </div>)
}
