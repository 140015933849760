import React, { useEffect, useState } from "react"
import classed from "classed-components"

interface LightColor {
    color: string
    selected?: boolean
    size?: string
}

export const Light = classed.div<LightColor>([
    "mx-auto",
    ({ size }) => (size ? `square-${size}` : ""),
    ({ color }) => `bg-${color}`,
    ({ color, selected }) => (selected ? `box-shadow-${color}` : ""),
    ({ selected }) => ({ "selected-border": selected }),
    ({ selected }) => ({ "unselected-border": !selected }),
    "light-pad",
])
