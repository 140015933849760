import React, { useEffect, useMemo, useState } from "react"
import { MockService, OkrBoard, RepoService } from "service"
import { OkrPanel } from "./okr-panel"
import { useObservable } from 'rxjs-hooks'
import {FireService} from "service"

export const Board = () => {

    // todo: again
    let [board, setBoard] = useState<OkrBoard>(MockService.emptyBoard)

    useEffect(() => {
        const subscription = RepoService.board$.subscribe(next => {
            if (next) {
                setBoard(next)
            }
        });
        // return unsubscribe method to execute when component unmounts
        return () => subscription.unsubscribe();
    }, []);



    const switchTo = (next:OkrBoard) => {
        setBoard(next)
    }


    return <div className="px-2 pb-2">
        <OkrPanel board={board} nav={switchTo}/>
    </div>
}
