// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyC-VjHceVw9B57mG8GnGXGfndsHbnbfwvI",
    authDomain: "idealokr.firebaseapp.com",
    databaseURL: "https://idealokr.firebaseio.com",
    projectId: "idealokr",
    storageBucket: "idealokr.appspot.com",
    messagingSenderId: "526315183082",
    appId: "1:526315183082:web:e6f44bdf61ac23de96b093",
    measurementId: "G-3MFKTNHQR9",
}

const app = firebase.initializeApp(firebaseConfig)

const db = app.firestore()

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: "https://localhost:8000/app/login",
    // This must be true.
    handleCodeInApp: true,
    // dynamicLinkDomain: "localhost",
}

const sendEmailLink = (email: string) => {
    firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function() {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            console.log(`email sent to ${email}`)
            window.localStorage.setItem("emailForSignIn", email)
        })
        .catch(function(error) {
            // Some error occurred, you can inspect the code: error.code
            console.log("send link error", error)
        })
}

const signInWithEmail = () => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn")
        if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt("Please provide your email for confirmation")
        }
        // The client SDK will parse the code from the link for you.
        firebase
            .auth()
            .signInWithEmailLink(email!, window.location.href)
            .then(function(result) {
                window.localStorage.removeItem("emailForSignIn")
                console.log("~~~~~", result)
            })
            .catch(function(error) {
                console.log("---error", error)
            })
    }
}

export type DocumentData = firebase.firestore.DocumentData

interface SingInResult {
    credential?: firebase.auth.UserCredential
    error?: any
}
const signInWithEmailAndPas = async (email: string, password: string) => {
    try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const credential = await firebase.auth().signInWithEmailAndPassword(email, password)
        return { credential: credential } as SingInResult
    } catch (e) {
        return { error: e } as SingInResult
    }
}

export const FireService = {
    app,
    db,
    signInWithEmailAndPas,
    sendEmailLink,
    auth: firebase.auth(),
}
